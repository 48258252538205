

import FETCHING from  '../../library/fetching'
import UMUM from  '../../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        kibA_id : '',
        uraian : 'GANTI RUGI TANAMAN TUMBUH DAN BANGUNAN',
        tahun : '',
        des_kel_uniq : '',
        keterangan : '',
        
      },


      penerima : {
        id : '',
        gati_rugi_id : '',
        nama : '',
        tpt_lahir : '',
        tgl_lahir : '',
        tgl_bayar : '',
        nik : '',
        tanah : 0,
        tanah_vol : 0,
        tanah_sat : '',
        bangunan : 0,
        bangunan_vol : 0,
        bangunan_sat : '',
        lainnya : 0,
        lainnya_vol : 0,
        lainnya_sat : '',
        lampiran : null,
      },

      filterku : {
        tahun : '',
        sub_unit_kerja_id : '',
      },

      lampiran : {
        type : '',
        file_old: "",
      },


      
      list_data : [],
      list_data_penerima : [],
     
      
      page_first: 1,
      page_last: 0,
      page_limit : 8,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,


      mdl_list_penerima : false,
      mdl_add_penerima : false,
      mdl_edit_penerima : false,
      mdl_hapus_penerima : false,

      mdl_lampiran : false,

      FETCHING : FETCHING,
      UMUM : UMUM,

      file_old: "",
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_ganti_rugi + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jmlData;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {

      


      fetch(this.$store.state.url.URL_ganti_rugi + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_ganti_rugi + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_ganti_rugi + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData : function(data){

        console.log(data);

        this.form.id = data.id ;
        this.form.kibA_id = data.kibA_id ;
        this.form.uraian = data.uraian ;
        this.form.tahun = data.tahun ;
        this.form.des_kel_uniq = data.des_kel_uniq ;
        this.form.keterangan = data.keterangan ;



        this.penerima.gati_rugi_id = data.id;

        this.tunggu(data.des_kel_uniq)
        this.getViewPenerima();
    },





    getViewPenerima : function(){
      // this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_ganti_rugi_penerima + "list", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            gati_rugi_id : this.form.id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data_penerima = res_data;
              console.log(res_data);
      });
    },


    addDataPenerima : function(number) {

     


      var formData = new FormData();
      formData.append('gati_rugi_id', this.penerima.gati_rugi_id);
      formData.append('nama', this.penerima.nama);
      formData.append('tpt_lahir', this.penerima.tpt_lahir);
      formData.append('tgl_lahir', this.penerima.tgl_lahir);
      formData.append('tgl_bayar', this.penerima.tgl_bayar);
      formData.append('nik', this.penerima.nik);
      formData.append('tanah', this.penerima.tanah);
      formData.append('tanah_vol', this.penerima.tanah_vol);
      formData.append('tanah_sat', this.penerima.tanah_sat);
      formData.append('bangunan', this.penerima.bangunan);
      formData.append('bangunan_vol', this.penerima.bangunan_vol);
      formData.append('bangunan_sat', this.penerima.bangunan_sat);
      formData.append('lainnya', this.penerima.lainnya);
      formData.append('lainnya_vol', this.penerima.lainnya_vol);
      formData.append('lainnya_sat', this.penerima.lainnya_sat);

      formData.append('file', this.penerima.lampiran);



      fetch(this.$store.state.url.URL_ganti_rugi_penerima + "addData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          // body: JSON.stringify(this.penerima)
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getViewPenerima();
      });
    },

    editDataPenerima : function(){
      fetch(this.$store.state.url.URL_ganti_rugi_penerima + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.penerima)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getViewPenerima();
      });
    },

    removeDataPenerima : function(E){
      fetch(this.$store.state.url.URL_ganti_rugi_penerima + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.penerima.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getViewPenerima();
      });

    },
    selectDataPenerima : function(data){

        console.log(data);

        this.penerima.id = data.id ;
        this.penerima.gati_rugi_id = data.gati_rugi_id ;
        this.penerima.nama = data.nama ;
        this.penerima.tpt_lahir = data.tpt_lahir ;
        this.penerima.tgl_lahir = data.tgl_lahir ;
        this.penerima.tgl_bayar = data.tgl_bayar ;
        this.penerima.nik = data.nik ;
        this.penerima.tanah = data.tanah ;
        this.penerima.tanah_vol = data.tanah_vol ;
        this.penerima.tanah_sat = data.tanah_sat ;
        this.penerima.bangunan = data.bangunan ;
        this.penerima.bangunan_vol = data.bangunan_vol ;
        this.penerima.bangunan_sat = data.bangunan_sat ;
        this.penerima.lainnya = data.lainnya ;
        this.penerima.lainnya_vol = data.lainnya_vol ;
        this.penerima.lainnya_sat = data.lainnya_sat ;


        this.lampiran.file_old = data.file;
        this.lampiran.type = data.mimetype ;

        this.tunggu(data.des_kel_uniq)
    },






    filterData : function (val, update, abort) {
            update(async () => {
              console.log(val);
              if (val === '') {}
              else {
                this.$store.state.listDesKel = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_DES_KEL + "autocomplete", {cari : val})
              }
            })
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        
        tunggu : async function(aa,bb, cc){
          this.$store.state.listSubUnitKerja = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_SUB_UNIT_KERJA + "autocomplete", {cari : cc})
          this.$store.state.listDesKel = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_DES_KEL + "autocomplete", {cari : aa})
          this.$store.state.listKibA = await FETCHING.FETCH_POST(this.$store.state.url.URL_KIB_A + "autocomplete", {cari : aa})
          this.$store.state.listSatuan = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_SATUAN + "list", {cari : aa})
        },


        filterUnitKerja : function (val, update, abort) {
            update(async () => {
              console.log(val);
              if (val === '') {}
              else {
                this.$store.state.listSubUnitKerja = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_SUB_UNIT_KERJA + "autocomplete", {cari : val})
              }
            })
        },
        filterKibA : function (val, update, abort) {
            update(async () => {
              console.log(val);
              if (val === '') {}
              else {
                this.$store.state.listKibA = await FETCHING.FETCH_POST(this.$store.state.url.URL_KIB_A + "autocomplete", {cari : val})

              }
            })
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    const d = new Date();
    this.form.tahun = d.getFullYear();


    this.tunggu('','', '')
    this.getView();
  },
}
